import { toast } from "react-toastify/dist/react-toastify";

const defaultConfig = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: "light",
};

export const notify = {
  info: (msg, config) => toast.info(msg, Object.assign(defaultConfig, config)),
  warn: (msg, config) => toast.warn(msg, Object.assign(defaultConfig, config)),
  success: (msg, config) => toast.success(msg, Object.assign(defaultConfig, config)),
};
