import React from 'react'
import CosTableOfContentsOverlayEntry from './CosTableOfContentsOverlayEntry'

class CosTableOfContentsOverlay extends React.Component {
  closeOverlay (_event) {
    this.props.overlayParent.toggleOpenCloseOverlay();
  }

  render () {
    const chapters = Object.keys(this.props.chapters).map((chapterNumber, index) =>
      <CosTableOfContentsOverlayEntry key={index} chapter={this.props.chapters[chapterNumber]} level={1} overlayParent={this.props.overlayParent} />
    );

    return <div id="toc_overlay">
             <div id="toc_overlay_close" onClick={(event) => { this.closeOverlay(event) }}>
               <i className="fa fa-times" aria-hidden="true"></i>
             </div>
             <h1>Table of contents</h1>
             <ul className="level-1">
               {chapters}
             </ul>
           </div>
  }
}

export default CosTableOfContentsOverlay
