import axios from "axios";
import useSWR from "swr";

// call for services list
export const fetchServicesList = (classobject, booking_phase_id) => {
  axios.get("/services.json?booking_form_id=" + booking_phase_id).then(response => {
    var servicesHash = [];
    response.data.map(service => {
      var serviceHash = {
        id: service.id,
        code: service.code,
        description: service.description,
        quantity: service.quantity,
        price: service.price,
        formatted_price: service.formatted_price,
        start_time: service.start_time,
        end_time: service.end_time,
        should_prompt_slot_number: service.should_prompt_slot_number,
        slot: service.slot,
        for_mn: service.for_mn,
        degressive_price: service.degressive_price,
        unit_scale: service.unit_scale,
        unit: service.unit,
        isValid: true,
        serviceIsCustom: service.service_is_custom,
        currency: service.currency,
        global_currency: service.global_currency,
      };
      servicesHash.push(serviceHash);
    });

    classobject.setState({
      services: servicesHash,
      loading: true,
    });
  });
};

// call for orderline
export const fetchOrderLine = (classobject, id) => {
  axios.get("/order_lines/" + id + ".json").then(response => {
    classobject.setState({
      orderline: {
        id: response.data.id,
        target: response.data.target,
        teams: response.data.teams,
        serviceCode: response.data.serviceCode,
        serviceDescription: response.data.serviceDescription,
        longPublicStatus: response.data.longPublicStatus,
        publicStatus: response.data.publicStatus,
        publicNote: response.data.publicNote,
        quantity: response.data.quantity,
        startTime: response.data.startTime,
        endTime: response.data.endTime,
        unitPrice: response.data.unitPrice,
        price: response.data.price,
        customerCode: response.data.customerCode,
        should_prompt_slot_number: response.data.should_prompt_slot_number,
        forMn: response.data.forMn,
        isQty1: response.data.isQty1,
        isValid: true,
        currency: response.data.currency,
        sport: response.data.sport,
        target_category_type: response.data.target_category_type,
        venue: response.data.venue,
        warm_up_match: response.data.warm_up_match
      },
      newStartTime: response.data.startTime,
      newEndTime: response.data.endTime,
      newQuantity: response.data.quantity,
    });
  });
};

export const fetcher = url => axios.get(url).then(res => res.data);

export function useCosData(previewMode = false) {
  const { data, error, mutate, isValidating } = useSWR(previewMode ? "/cos.json?preview=true" : "/cos.json", fetcher);

  const loading = !error && !data;
  return { data, error, loading, mutate, isValidating };
}

export const useEvents = () => useSWR("/events.json", fetcher);
export const useCustomers = () => useSWR("/customers.json", fetcher);
