import React from 'react'

class Service extends React.Component {
  render () {
    return (
      <tr>
        <td className="code">{ this.props.code }</td>
        <td className="description">{ this.props.description }</td>
        <td className="price">{!['guideline','leaflet'].includes(this.props.pageType) && this.props.price }</td>
      </tr>
    );
  }
}

export default Service
