import React, { useEffect, useRef, useState } from "react";
import CosTableOfContents from "./cos/CosTableOfContents";
import CosChapter from "./cos/CosChapter";
import BackToTheTopButton from "./extracted_components/BackToTheTopButton";
import PhotoSwipeGallery from "./cos/PhotoSwipeGallery";
import { useCosData } from "../apis/ApiCall";
import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";
import { withRouter } from "react-router-dom";
import "../css/Cos.css.scss";

function CosFC(props) {
  const [previewMode, setPreviewMode] = useState(false);
  const { data, loading, isValidating } = useCosData(previewMode);

  const hash = props.location.hash;
  useEffect(() => {
    // scroll on hash change
    if (hash === null || !hash.startsWith("#")) return;
    const chapterNumberFrom = (hash) => hash.split`#`.pop().split`/`.pop();
    scrollToChapter(chapterNumberFrom(hash));
  }, [hash]);

  const chaptersRef = useRef(null);

  function getMap() {
    if (!chaptersRef.current) chaptersRef.current = new Map();
    return chaptersRef.current;
  }

  const scrollToChapter = (chapterNumber = 0) => {
    const map = getMap();
    const node = map.get(chapterNumber);
    if (!node) return;
    node.scrollIntoView({ behavior: "smooth" });
  };

  const chapters = data ? data.chapters : null;

  function selectChaptersToDisplay(chapters, filterChaptersBy = null) {
    if (!filterChaptersBy) return chapters;

    const selectedChapterNumbers = chapters
      .filter(filterChaptersBy)
      .map((chapter) => chapter.chapter_number);

    function getParentChapterNumbers(chapterNumbers) {
      const numbers = chapterNumbers.split`.`;
      if (numbers.length <= 2) return [];
      const [cn1, cn2, ...rest] = numbers;
      return [cn1, `${cn1}.${cn2}`];
    }

    const chapterNumbersToDisplay = [
      ...new Set(
        selectedChapterNumbers.flatMap((cn) => getParentChapterNumbers(cn))
      ),
    ].concat(selectedChapterNumbers);

    function compareChapterNumber(c1, c2) {
      const cn1 = c1.chapter_number,
        cn2 = c2.chapter_number;
      if (cn1 < cn2) return -1;
      if (cn1 > cn2) return 1;
      return 0;
    }

    return chapters
      .filter((chapter) =>
        chapterNumbersToDisplay.includes(chapter.chapter_number)
      )
      .sort(compareChapterNumber);
  }

  const chaptersToDisplay = (function (chapters, filterChaptersBy) {
    if (!chapters) return null;
    if (!filterChaptersBy) return chapters;
    return selectChaptersToDisplay(chapters, filterChaptersBy);
  })(chapters, props.filterChaptersBy);

  const pageType = props.match.url;
  const CosChapters = ({ chapters }) => (
    <div>
      {chapters.map((chapter, index) => (
        <div
          key={chapter.id}
          ref={(node) => {
            const map = getMap();
            node
              ? map.set(chapter.chapter_number, node)
              : map.delete(chapter.chapter_number);
          }}
        >
          <CosChapter index={index} {...chapter} pageType={pageType} />
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    if (!chaptersRef.current) return;
    const getFirstChapterNumber = () => {
      return Array.from(chaptersRef.current.keys()).sort().shift();
    };
    if (['/guidelines', '/technical_leaflets'].includes(pageType)) {
      scrollToChapter(getFirstChapterNumber());
    }

  }, [pageType]);

  const draftEnabled = data ? data.draft_enabled : false;

  const togglePreviewMode = () => {
    setPreviewMode((previewMode) => !previewMode);
  };

  const PreviewButton = ({ previewMode }) => {
    return (
      <span>
        {previewMode ? "Draft Version" : "Publish Version"}
        &nbsp;
        <a
          href="#"
          className={
            isValidating
              ? "btn btn-outline-primary btn-disabled"
              : "btn btn-outline-primary"
          }
          data-toggle="button"
          aria-pressed="false"
          autoComplete="off"
          disabled={isValidating}
          onClick={(event) => {
            event.preventDefault();
            togglePreviewMode(event);
          }}
        >
          <i className="fa fa-exchange"></i>
        </a>
      </span>
    );
  };

  useEffect(() => {
    // set up image gallery, a copy&paste from the former class component version
    const imageOnClick = (e) => {
      e.preventDefault();
      let img = e.target;
      let pswpElement = document.querySelectorAll(".pswp")[0];

      let parent = img.closest(".cos-service") || img.closest(".chapter");

      let items = [];
      let index = 0;

      if (parent === null) {
        items = [
          {
            src: img.getAttribute("src"),
            w: img.naturalWidth,
            h: img.naturalHeight,
          },
        ];
      } else {
        parent.querySelectorAll("img").forEach(function (image, i) {
          items.push({
            src: image.getAttribute("src"),
            w: image.naturalWidth,
            h: image.naturalHeight,
          });
          if (image.getAttribute("src") === img.getAttribute("src")) {
            index = i;
          }
        });
      }

      // List of options: https://photoswipe.com/documentation/options.html
      let options = {
        index: index, // start at first slide,
        shareEl: false, // disable social sharing options
        closeOnScroll: false, // disabled it because it doesn't close properly
        closeOnVerticalDrag: false, // disabled it because it doesn't close properly
        history: false, // No need to mess up with the URLs
      };

      let gallery = new PhotoSwipe(
        pswpElement,
        PhotoSwipeUI_Default,
        items,
        options
      );
      gallery.init();
    };

    const setupPhotoSwipeGallery = () => {
      document.querySelectorAll(".cos img:not(.pswp__img)").forEach((img) => {
        img.removeEventListener("click", imageOnClick);
        img.addEventListener("click", imageOnClick);
      });
    };

    const tearDownPhotoSwipeGallery = () => {
      document.querySelectorAll(".cos img:not(.pswp__img)").forEach((img) => {
        img.removeEventListener("click", imageOnClick);
      });
    };

    if (chaptersToDisplay && chaptersToDisplay.length > 0)
      setupPhotoSwipeGallery();
  }, [isValidating, loading, hash]);

  return (
    <div className="cos">
      {draftEnabled && <PreviewButton previewMode={previewMode} />}
      {!loading && <CosTableOfContents chapters={chapters} />}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <CosChapters chapters={chaptersToDisplay} />
      )}
      <BackToTheTopButton />
      <PhotoSwipeGallery />
    </div>
  );
}

export default withRouter(CosFC);
