import React from 'react'
import { interceptLinkClick } from './utils'
import { withRouter } from 'react-router-dom'

class CosTableOfContentsOverlayEntry extends React.Component {
  close (_event) {
    this.props.overlayParent.toggleOpenCloseOverlay();
    return true;
  }

  contentClickHandler = interceptLinkClick(this.props);

  openChapter (event) {
    var parentLink = event.target.parentNode;
    var ul = parentLink.nextSibling;

    if (parentLink.className.includes('closed')) {
      parentLink.className = 'chevron opened';
      ul.classList.add('opened');
    } else {
      parentLink.className = 'chevron closed';
      ul.classList.remove('opened');
    }
  }

  render () {
    const number = this.props.chapter.number
    const title = this.props.chapter.title
    const CosTableOfContentsOverlayEntryWithRouter = withRouter(CosTableOfContentsOverlayEntry)
    const chapters = this.props.chapter.chapters ? Object.keys(this.props.chapter.chapters).map((chapterNumber, index) =>
      <CosTableOfContentsOverlayEntryWithRouter key={index} chapter={this.props.chapter.chapters[chapterNumber]} level={parseInt(this.props.level) + 1} overlayParent={this.props.overlayParent} />
    ) : null

    if (number=="0") {
      return null;
    }

    return <li>
             <a href={`cos#${number}`} aria-haspopup="true" onClick={(event) => { this.contentClickHandler(event); this.close(event); }} title={number + ' ' + title}>
               <span className="chapter_number">{number}</span>
               <span className="chapter_title">{title}</span>
             </a>
             { (chapters && (chapters.length > 0)) && <a onClick={(event) => { this.openChapter(event) }} className="chevron closed"><i className="fa fa-chevron-down" aria-hidden="true"></i></a>}
              <ul className={"level-" + (parseInt(this.props.level) + 1)}>
                {chapters}
              </ul>
           </li>
  }
}

export default withRouter(CosTableOfContentsOverlayEntry)
