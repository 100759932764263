import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "../../../css/parapan.css";
import Form from "react-bootstrap/Form";
import DateTime from "react-datetime";
import "../../../css/react-datetime.css";
import { useNewOrderContext } from "../NewOrderContext";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import moment from "moment";

function TargetServicesList() {
  const { newOrder, dispatch, computed } = useNewOrderContext();
  const [composedServices, setComposedServices] = useState([]);
  const [modalText, setModalText] = useState({});
  const services = computed.servicesByCategoryType;
  const bookingServices = computed.bookingServices;

  useEffect(() => {
    const services = composingServices();
    setComposedServices(services);
  }, [bookingServices]);

  useEffect(() => {
    //trigged when selected target changes & step 3 to step 2
    calculateQuantity();
  }, [computed.targets]);

  const serviceTotal = service => {
    const filterService = bookingServices.filter(s => service.id === s.service_id);
    if (filterService.length > 0 && computed.targets.length > 0) {
      return filterService[0].requested_quantity
        ? parseInt(filterService[0].requested_quantity) * service.price
        : 0;
    } else {
      return 0;
    }
  };

  const sumTotalPerMatch = () => {
    let sum = null;
    if (computed.targets.length > 0) {
      services.forEach(s => {
        bookingServices.forEach(bookedService => {
          if (s.id === bookedService.service_id) {
            bookedService.requested_quantity &&
              (sum += s.price * parseInt(bookedService.requested_quantity));
          }
        });
      });
    }
    return sum ? `${sum}` : 0;
  };

  const totalPrice = () => {
    //warm_up match price is 0
    const targets = computed.targets.filter(target => !target.warm_up_match);
    return targets.length * sumTotalPerMatch();
  };

  const handleFormInputs = (e, service, attr = null) => {
    const bookedServices = bookingServices.filter(s => service.id === s.service_id);
    if (bookedServices.length > 0) {
      const newServicesList = bookingServices.map(s => {
        if (service.id === s.service_id) {
          if (attr) {
            if (["requested_start_time", "requested_end_time"].includes(attr)) {
              s[attr] = e.format("H:mm");
              calculateMinQuantity(s, e.format("H:mm"), attr);
            }
            if (attr === "pre_post_slot") {
              s[attr] = e.target.value;
              s["requested_quantity"] = 1;
            }
            if (attr === "tech_note") {
              s[attr] = modalText.tech_note;
            }
          } else {
            s[e.target.name] = e.target.value;
          }
          return s;
        } else {
          return s;
        }
      });
      dispatch({ type: "setBookingServices", bookingServices: newServicesList });
    } else {
      let newService = {
        service_id: service.id,
        description: service.description,
        code: service.code,
        price: service.price,
        target_id: null,
        tech_note: null,
        requested_quantity: null,
        requested_start_time: null,
        requested_end_time: null,
        pre_post_slot: null,
      };
      if (attr) {
        if (["requested_start_time", "requested_end_time"].includes(attr)) {
          newService[attr] = e.format("H:mm");
          calculateMinQuantity(newService, e.format("H:mm"), attr);
        }
        if (attr === "pre_post_slot") {
          newService[attr] = e.target.value;
          newService["requested_quantity"] = 1;
        }
        if (attr === "tech_note") {
          newService[attr] = modalText.tech_note;
        }
      } else {
        newService[e.target.name] = e.target.value;
      }
      dispatch({
        type: "setBookingServices",
        bookingServices: [...bookingServices, newService],
      });
    }
  };

  const assignTimes = (bookingService, inputTime, attr) => {
    let start_hour = null;
    let start_min = null;
    let end_hour = null;
    let end_min = null;
    if (attr == "requested_start_time" && bookingService.requested_end_time) {
      start_hour = parseInt(inputTime.split(":")[0], 10);
      start_min = parseInt(inputTime.split(":")[1], 10);
      end_hour = parseInt(bookingService.requested_end_time.split(":")[0], 10);
      end_min = parseInt(bookingService.requested_end_time.split(":")[1], 10);
    } else if (attr == "requested_end_time" && bookingService.requested_start_time) {
      start_hour = parseInt(bookingService.requested_start_time.split(":")[0], 10);
      start_min = parseInt(bookingService.requested_start_time.split(":")[1], 10);
      end_hour = parseInt(inputTime.split(":")[0], 10);
      end_min = parseInt(inputTime.split(":")[1], 10);
    }
    return [start_hour, start_min, end_hour, end_min];
  };

  const getTimeBasedOnTargetTime = (time, targetTime) => {
    const hour = parseInt(time.split(":")[0]);
    const minute = parseInt(time.split(":")[1]);

    let prefix = targetTime.split("T")[0];
    let suffix = targetTime.split("T")[1].split("");

    if (hour > 9) {
      suffix[0] = String(hour)[0];
      suffix[1] = String(hour)[1];
    } else {
      suffix[0] = "0";
      suffix[1] = String(hour)[0];
    }

    if (minute > 9) {
      suffix[3] = String(minute)[0];
      suffix[4] = String(minute)[1];
    } else {
      suffix[3] = "0";
      suffix[4] = String(minute)[0];
    }

    return `${prefix}T${suffix.join("")}`;
  };

  const getDateForTarget = (target, time) => {
    var hour = parseInt(time.split(":")[0]);
    var minute = parseInt(time.split(":")[1]);

    var targetStartTime = moment(target.start_time);
    var targetEndTime = moment(target.end_time);

    const t1 = moment(getTimeBasedOnTargetTime(time, target.start_time));
    const t2 = moment(getTimeBasedOnTargetTime(time, target.end_time));

    if (t1.isBetween(targetStartTime, targetEndTime, "minute", "[]")) {
      return t1;
    } else {
      if (t2.isBetween(targetStartTime, targetEndTime, "minute", "[]")) {
        return t2;
      } else {
        // This should only happen if a target doesn't last 24h
        return null;
      }
    }
  };

  function calculateMinQuantity(bookingService, inputTime, attr) {
    if (inputTime) {
      // assign requested start/end time
      const [start_hour, start_min, end_hour, end_min] = assignTimes(
        bookingService,
        inputTime,
        attr
      );
      if (bookingService.requested_start_time && bookingService.requested_end_time) {
        setBookingServiceMinQuantity(bookingService);
      } else {
        bookingService["requested_quantity"] = null;
      }
    }
  }

  const removeBookingQuantity = (service, event, attr) => {
    const bookedServices = bookingServices.filter(s => service.id === s.service_id);
    if (bookedServices.length > 0) {
      const newServicesList = bookingServices.map(s => {
        if (service.id === s.service_id) {
          s[attr] = "";
          s["requested_quantity"] = null;
          return s;
        } else {
          return s;
        }
      });
      dispatch({ type: "setBookingServices", bookingServices: newServicesList });
    }
  };

  const manageTimeChange = (service, event, attr) => {
    if (event == "") {
      removeBookingQuantity(service, event, attr);
    } else {
      if (typeof event.format == "function") {
        handleFormInputs(event, service, attr);
      }
    }
  };

  const manageSlotChange = (event, service, attr) => {
    event.target.value
      ? handleFormInputs(event, service, attr)
      : removeBookingQuantity(service, event, attr);
  };

  const getServiceTimeQuantity = service => {
    const bookedServices = bookingServices.filter(s => service.id === s.service_id);
    return bookedServices.length > 0 ? bookedServices[0].requested_quantity : "";
  };

  const inputs = service => {
    if (service.should_prompt_slot_number) {
      return <td className="slot">{slotField(service)}</td>;
    }
    if (service.for_mn) {
      return (
        <>
          <td className="datetime_picker">
            {service.for_mn && (
              <DateTime
                className="mr-1"
                dateFormat={false}
                timeFormat={"H:mm"}
                inputProps={{
                  id: "start-time-input",
                  placeholder: "Start Time",
                }}
                value={service.requested_start_time}
                onChange={event => manageTimeChange(service, event, "requested_start_time")}
              />
            )}
            {service.for_mn && (
              <DateTime
                className="ml-1"
                dateFormat={false}
                timeFormat={"H:mm"}
                inputProps={{
                  id: "end-time-input",
                  placeholder: "End Time",
                }}
                value={service.requested_end_time}
                onChange={event => manageTimeChange(service, event, "requested_end_time")}
              />
            )}
          </td>
        </>
      );
    }
    if (!service.for_mn && !service.should_prompt_slot_number) {
      return (
        <td>
          <Form.Control
            onChange={e => handleFormInputs(e, service)}
            name="requested_quantity"
            size="sm"
            type="text"
            id="quantity"
            value={service.requested_quantity || ""}
            placeholder="Qty"
          />
        </td>
      );
    }
  };

  const slotField = service => {
    const slotOptions = [<option value="">Select a slot</option>];
    for (var i = 1; i <= service.slot_count; i++) {
      slotOptions.push(<option value={i}>Slot {i}</option>);
    }
    return (
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Control
          as="select"
          custom
          onChange={event => {
            manageSlotChange(event, service, "pre_post_slot");
          }}
          value={service.pre_post_slot || ""}
          size="sm"
        >
          {slotOptions}
        </Form.Control>
      </Form.Group>
    );
  };

  const composingServices = () => {
    const bsMap = new Map(bookingServices.map(s => [s.service_id, s]));
    const composedServices = services.map(service =>
      bsMap.has(service.id)
        ? {
            ...bsMap.get(service.id),
            id: service.id,
            for_mn: service.for_mn,
            slot_count: service.slot_count,
            unit: service.unit,
            unit_scale: service.unit_scale,
            should_prompt_slot_number: service.should_prompt_slot_number,
            show_note_modal: false,
          }
        : {
            ...service,
            for_mn: service.for_mn,
            slot_count: service.slot_count,
            unit: service.unit,
            unit_scale: service.unit_scale,
            should_prompt_slot_number: service.should_prompt_slot_number,
            pre_post_slot: null,
            tech_note: service.tech_note,
            show_note_modal: false,
          }
    );
    const sortServicesByCode = composedServices.sort((a, b) => {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
    return sortServicesByCode;
  };

  const moneyFormatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const handleModalInput = (e, service) => {
    setModalText({ id: service.id, tech_note: e.target.value });
  };

  const setTextNote = service => {
    if (service.tech_note) {
      setModalText({ id: service.id, tech_note: service.tech_note });
    }
  };

  const modelTextVal = service => {
    if (service.id === modalText.id) {
      return modalText.tech_note || "";
    }
  };

  const handleCloseModal = service => {
    const newComposingServices = composedServices.map(s => {
      if (service.id === s.id) {
        return { ...s, show_note_modal: false };
      } else {
        return s;
      }
    });
    setComposedServices(newComposingServices);
  };
  const handleOpenModal = (e, service) => {
    const newComposingServices = composedServices.map(s => {
      if (service.id === s.id) {
        return { ...s, show_note_modal: true };
      } else {
        return s;
      }
    });
    setComposedServices(newComposingServices);
  };

  function calculateQuantity() {
    const newBookingServices = bookingServices.map(bookingService => {
      if (bookingService.requested_start_time && bookingService.requested_end_time) {
        return setBookingServiceMinQuantity(bookingService);
      } else {
        return bookingService;
      }
    });
    dispatch({ type: "setBookingServices", bookingServices: newBookingServices });
  }

  function setBookingServiceMinQuantity(bookingService) {
    const targets = computed.targets;
    if (targets.filter(t => t.start_time !== null).length == 0) {
      bookingService["requested_quantity"] = null;
    } else {
      const target = targets.filter(t => t.start_time !== null)[0];
      const startDateTime = getDateForTarget(target, bookingService.requested_start_time);
      const endDateTime = getDateForTarget(target, bookingService.requested_end_time);
      if (startDateTime == null || endDateTime == null) {
        bookingService["requested_quantity"] = null;
      }
      const offsetMin = (endDateTime - startDateTime) / 60000;
      if (offsetMin <= 0) {
        bookingService["requested_quantity"] = 0;
      } else {
        const service = services.filter(service => service.id === bookingService.service_id)[0];
        const quantity = Math.ceil(offsetMin / service.unit_scale);
        bookingService["requested_quantity"] = quantity;
      }
    }
    return bookingService;
  }

  return (
    <div className="taget_services_list_container">
      <Table className="target_services_list_table">
        <tbody>
          {composedServices.map(service => (
            <tr key={service.id}>
              <td>{service.code}</td>
              <td className="service_code">{service.description}</td>
              <td className="tech_note">
                <Form.Control
                  readOnly
                  disabled
                  name="tech_note"
                  className="tech_note"
                  type="text"
                  value={service.tech_note || ""}
                  placeholder="Tech note"
                />
                <Button
                  className="button_tech_note"
                  variant="outline-info"
                  size="sm"
                  onClick={e => {
                    setTextNote(service);
                    handleOpenModal(e, service);
                  }}
                >
                  Edit Tech note
                </Button>
                <Modal
                  show={service.show_note_modal}
                  onHide={() => handleCloseModal(service)}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <p>
                        {service.code} - {service.description}
                      </p>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form.Control
                      name="tech_note"
                      onChange={e => handleModalInput(e, service)}
                      className="tech_note"
                      size="lg"
                      as="textarea"
                      rows={8}
                      placeholder="Tech note"
                      value={modelTextVal(service)}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleCloseModal(service)}>
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={e => handleFormInputs(e, service, "tech_note")}
                    >
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </td>
              {inputs(service)}
              <td className="service_price">
                {computed.currency} {moneyFormatter.format(service.price)}
              </td>
              <td className="service_currency">
                {computed.currency} {moneyFormatter.format(serviceTotal(service))}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {services[0] && (
        <p className="font-weight-bold">
          Price per match: {computed.currency} {moneyFormatter.format(sumTotalPerMatch())}
        </p>
      )}
      {services[0] && (
        <p className="font-weight-bold">
          Total Price of the Order: {computed.currency} {moneyFormatter.format(totalPrice())}
        </p>
      )}
    </div>
  );
}

export default TargetServicesList;
