// Intercept normal <a/> link click,
// if target url is with-in the same page, programmatically navigate by 'history' from react-router instead
// why not use <Link/> from react-router ?
// Because we are 'dangerouslySetInnerHTML'
export const interceptLinkClick =
  ({ history }) =>
  (e) => {
    const targetLink = e.target.closest("a");

    const linkContainsHash = Boolean(targetLink?.hash);
    const sameHost = Boolean(window.location.host === targetLink?.host);
    const samePage = Boolean(window.location.pathname === targetLink?.pathname);

    if (linkContainsHash && sameHost && samePage) {
      e.preventDefault();
      history.push(targetLink.hash);
    }
  };
