import React from "react"

class BackToTheTopButton extends React.Component {
  constructor(props){
    super(props);
  }

  showOnScroll = (id) => {
    window.onscroll = (event) => {
      var maxHeight = 1000;
      var height = event.currentTarget.scrollY;
      var button = document.getElementById(id);

      if ((button !== undefined) && (button !== null)) {
        button.style.opacity = height > maxHeight ? '1' : '0';
      }
    }
  }

  renderBackToTopButton = (id) => {
    return (
      <button id={id}
              onClick={ () => { window.scroll(0, 0); } }
              className='btn btn-light btn-md'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" x="0px" y="0px">
          <defs>
            <polygon id="a" points="12.445 4 21.743 14.331 20.257 15.669 13 7.606 13 22 11 22 11 7.606 3.743 15.669 2.257 14.331 11.555 4 2 4 2 2 22 2 22 4"/>
          </defs>
          <g fill="none" fillRule="evenodd">
            <use fill="#333" fillRule="nonzero" xlinkHref="#a"/>
          </g>
        </svg>
      </button>
    )
  }

  backToTheTopRender() {
    var idButton = this.props.idButton || 'back-to-the-top-button';

    this.showOnScroll(idButton);
    return this.renderBackToTopButton(idButton);
  }

  render (){
    return(
      <div>
        { this.backToTheTopRender() }
      </div>
    )
  }
};

export default BackToTheTopButton;