import React from "react";
import Service from "./Service";
import { GuidelineLeaflet } from "./GuidelineLeaflet";
import { GuidelineLeafletPage } from "./GuidelineLeafletPage";
import { withRouter } from "react-router-dom";
import { interceptLinkClick } from "./utils";

class CosService extends React.Component {
  contentClickHandler = interceptLinkClick(this.props);

  render() {
    let services = this.props.services.map((service) => (
      <Service key={service.id} {...service} pageType={this.props.pageType} />
    ));

    let mainClassName = "cos-service";
    if (this.props.new) {
      mainClassName += " new-cos-service";
    }
    let footer = (
      <div
        onClick={this.contentClickHandler}
        className="cos-footnote"
        dangerouslySetInnerHTML={{ __html: this.props.cos_footnote }}
      />
    );
    let description = (
      <div
        onClick={this.contentClickHandler}
        className="cos-description"
        dangerouslySetInnerHTML={{ __html: this.props.cos_description }}
      />
    );
    //guideline leaflet page
    if (["/guidelines", "/technical_leaflets"].includes(this.props.pageType)) {
      return (
        <GuidelineLeafletPage
          pageType={this.props.pageType}
          service={this.props.service}
          services={services}
          guidelines={this.props.guidelines}
          leaflets={this.props.technical_leaflets}
          chapter_number={this.props.chapter_number}
        />
      );
    } else {
      //cos page
      return (
        <div className={mainClassName}>
          <h2>{this.props.cosServicesCount == 1 ? "" : this.props.service}</h2>
          {this.props.new ? <div className="new-badge" /> : null}
          {description}
          {services.length > 0 && (
            <table className="services">
              <tbody>{services}</tbody>
            </table>
          )}
          {this.props.guidelines && this.props.chapter_number !== "0" && (
            <GuidelineLeaflet
              guidelineleaflet={this.props.guidelines}
              pageType={false}
              title={"Service Guidelines"}
            />
          )}
          {this.props.technical_leaflets &&
            this.props.chapter_number !== "0" && (
              <GuidelineLeaflet
                guidelineleaflet={this.props.technical_leaflets}
                pageType={false}
                title={"Technical Leaflets"}
              />
            )}
          {footer}
        </div>
      );
    }
  }
}

export default withRouter(CosService);
