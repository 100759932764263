import React from "react"
import Orderline from './orderlines/Orderline'
import '../css/Orders.css';

class Order extends React.Component {
  render () {
    const { order } =  this.props;

    let orderLines = order.order_lines.map((orderline) => {
      return <Orderline {...orderline} displayPrices={true} editable={false} key={orderline.id}/>
    })

    let table = (
      <div className="orderlines-list">
        <div className="orderlines-list-table">
          <table className="table">
            <tbody>
              { orderLines }
            </tbody>
          </table>
        </div>
      </div>)

    let summary = (
      <div id="order_summary">
        <div>
          Order # { order.ordernumber }
        </div>
        <div>
          Total Price of the Order: { order.total_ordered }
        </div>
        <div>
          Submitted on { order.created_at }
        </div>
        <div>
          by { order.contact_name }
        </div>
      </div>
    )

    return (
      <div>
        {table}
        {summary}
      </div>
    );
  }
}

export default Order
