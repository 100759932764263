import React from "react";
import { GuidelineLeaflet } from "./GuidelineLeaflet";

export const GuidelineLeafletPage = ({
  pageType,
  service,
  services,
  guidelines,
  leaflets,
  chapter_number,
}) => {
  if (pageType === "/guidelines") {
    return guidelines ? (
      //only display Guideline component when the chapter_number is 0
      chapter_number === "0" ? (
        <GuidelineLeaflet
          guidelineleaflet={guidelines}
          pageType={pageType}
          title={"Service Guidelines"}
        />
      ) : (
        <div>
          <div className="cos-service-name">
            <div>{service}</div>
          </div>
          {services.length > 0 && (
            <table className="services">
              <tbody>{services}</tbody>
            </table>
          )}
          <GuidelineLeaflet
            guidelineleaflet={guidelines}
            pageType={pageType}
            title={"Service Guidelines"}
          />
        </div>
      )
    ) : (
      <div></div>
    );
  } else if (pageType === "/technical_leaflets") {
    return leaflets ? (
      chapter_number === "0" ? (
        <GuidelineLeaflet
          guidelineleaflet={leaflets}
          pageType={pageType}
          title={"Technical Leaflets"}
        />
      ) : (
        <div>
          <div className="cos-service-name">
            <div>{service}</div>
          </div>
          {services.length > 0 && (
            <table className="services">
              <tbody>{services}</tbody>
            </table>
          )}
          <GuidelineLeaflet
            guidelineleaflet={leaflets}
            pageType={pageType}
            title={"Technical Leaflets"}
          />
        </div>
      )
    ) : (
      <div></div>
    );
  }
};
