import React, { useEffect } from "react";
import { Card, Col, ListGroup, Row, Spinner } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { useNewOrderContext } from "../NewOrderContext";
import { useEvents } from "../../../apis/ApiCall";
import NewOrderFooter from "../NewOrderFooter";
import TargetServicesList from "../TargetServicesList";
import Button from "react-bootstrap/Button";
import { notify } from "../../NotificationContainer";

function ChooseTargetService(props) {
  const { newOrder, dispatch, computed } = useNewOrderContext();
  const bookingServices = computed.bookingServices;
  const events = useEvents()?.data?.events;
  useEffect(() => {
    const container = document.querySelector(".container");
    container.classList.replace("container", "container-fluid");
    return () => {
      const container = document.querySelector(".container-fluid");
      container.classList.replace("container-fluid", "container");
    };
  }, []);

  useEffect(() => {
    //auto set target when they is only one target from chosen target category
    if (computed.targetsBySportByCategoryType.length === 1) {
      const target = computed.targetsBySportByCategoryType[0];
      const firstComputedTarget = computed.targets[0];
      if (target.id !== firstComputedTarget?.id) {
        dispatch({
          type: "toggleTarget",
          target: target,
        });
      }
    }
  }, [computed.targetsBySportByCategoryType]);

  const targetList = () => {
    if (computed.targetsBySportByCategoryType.length === 1) {
      const target = computed.targetsBySportByCategoryType[0];
      return (
        <ListGroup.Item action active={true} key={target.id}>
          {target.name}
        </ListGroup.Item>
      );
    } else {
      return computed.targetsBySportByCategoryType
        .sort(function (a, b) {
          return (b.rank != null) - (a.rank != null) || a.rank - b.rank;
        })
        .map(t => (
          <ListGroup.Item
            action
            active={newOrder?.targets?.map(selectedTargets => selectedTargets.id).includes(t.id)}
            key={t.id}
            onClick={() => {
              dispatch({
                type: "toggleTarget",
                target: t,
              });
            }}
          >
            {t.name}
          </ListGroup.Item>
        ));
    }
  };

  if (!computed.stepOneComplete) {
    return (
      <>
        <h2 className={"mb-4"}>Create new order - Step 2</h2>
        <Card>
          <Card.Body>
            <Card.Title>Error</Card.Title>
            <Card.Text>You have not complete step 1.</Card.Text>
            <Link to={"/orders/new"} className={"btn btn-primary"}>
              Back to Step 1
            </Link>
          </Card.Body>
        </Card>
      </>
    );
  }

  const { sport, event } = newOrder;

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <Link to={"/orders/new"} className="glyphicon glyphicon-arrow-left mr-3 mb-2"></Link>
        <h2>Create new order - Step 2</h2>
      </div>
      <Row className="mb-5">
        <Col sm={3}>
          <Card>
            <Card.Header as="h5">
              {event.name} / {sport.name} / {sport.discipline} / {sport.gender}
            </Card.Header>
            <Card.Body>
              {events ? (
                <>
                  <ListGroup horizontal>
                    {sport.target_categories.map(tc => (
                      <ListGroup.Item
                        action
                        key={tc.id}
                        active={newOrder?.target_category_id === tc.id}
                        onClick={() => {
                          dispatch({
                            type: "setCategoryType",
                            target_category_id: tc.id,
                          });
                          dispatch({
                            type: "setTargets",
                            targets: [],
                          });
                          dispatch({
                            type: "setBookingServices",
                            bookingServices: [],
                          });
                        }}
                      >
                        <span style={{ textTransform: "capitalize" }}>{tc.name}</span>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>

                  <ListGroup className={"mt-4"}>{targetList()}</ListGroup>
                </>
              ) : (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col sm={9}>
          <TargetServicesList />
        </Col>
      </Row>

      {newOrder && (
        <NewOrderFooter
          rightSlot={
            <Button
              className="btn btn-primary"
              onClick={() => {
                const emptyTargets = computed?.targets?.length === 0;
                const emptyBookingServices = bookingServices.length === 0;
                const someQuantity = bookingServices.some(x => x.requested_quantity);

                if (emptyTargets) notify.info("No target selected.");
                if (emptyBookingServices) notify.info("No services booked.");
                if (!someQuantity) notify.info("No quantity selected.");
                if (emptyTargets || emptyBookingServices || !someQuantity) return;
                props.history.push("/orders/new/3");
              }}
            >
              Next <i className="fa fa-chevron-right"></i>
            </Button>
          }
        >
          Event: {newOrder?.event?.name || ""}
          <span style={{ width: "2ch" }} />
          Sport: {newOrder?.sport?.name} {newOrder?.sport?.discipline} {newOrder?.sport?.gender}
          <span style={{ width: "2ch" }} />
          Targets Count: {newOrder?.targets?.length || 0}
        </NewOrderFooter>
      )}
    </>
  );
}

export default withRouter(ChooseTargetService);
