import React from 'react'
import axios from 'axios'
import CosChapter from './cos/CosChapter'
import CosTableOfContents from './cos/CosTableOfContents'
import '../css/Cos.css.scss'
import BackToTheTopButton from './extracted_components/BackToTheTopButton'

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';
import PhotoSwipeGallery from './cos/PhotoSwipeGallery';

class Cos extends React.Component {
  _isMounted = false;
  _hasCheckedHashNavigation = false;

  constructor(props){
    super(props);
    this.state = {
      chapters: [],
      loading: true,
      preview_mode: false,
      draft_enabled: false
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this._hasCheckedHashNavigation = false;
    this.loadChapters();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadChapters() {
    let url = '/cos.json'
    if (this.state.preview_mode) {
      url += '?preview=true'
    }

    axios.get(url)
      .then(response =>{
        if (this._isMounted) {
          this.setState({chapters: response.data.chapters, draft_enabled: response.data.draft_enabled, loading: false});
        }
      }).catch(error => {
        console.log(error);
      });
  }

  togglePreviewMode(_event) {
    this.setState({preview_mode: !this.state.preview_mode,
                   chapters: [],
                   loading: true}, this.loadChapters)
  }

  imageOnClick(e) {
    e.preventDefault();
    var img = e.target;
    var pswpElement = document.querySelectorAll('.pswp')[0];

    var parent = img.closest('.cos-service') || img.closest('.chapter');

    var items = [];
    var index = 0;

    if (parent == null) {
      items = [{
        src: img.getAttribute('src'),
        w: img.naturalWidth,
        h: img.naturalHeight
      }];
    } else {
      parent.querySelectorAll('img').forEach(function(image, i){
        items.push({
          src: image.getAttribute('src'),
          w: image.naturalWidth,
          h: image.naturalHeight
        });
        if (image.getAttribute('src') == img.getAttribute('src')) {
          index = i;
        }
      });
    }

    // List of options: https://photoswipe.com/documentation/options.html
    var options = {
      index: index, // start at first slide,
      shareEl: false, // disable social sharing options
      closeOnScroll: false, // disabled it because it doesn't close properly
      closeOnVerticalDrag: false, // disabled it because it doesn't close properly
      history: false // No need to mess up with the URLs
    };

    var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
  }

  setupPhotoSwipeGallery() {
    let self = this;
    document.querySelectorAll('.cos img:not(.pswp__img)').forEach(function(img){
      img.removeEventListener('click', self.imageOnClick);
      img.addEventListener('click', self.imageOnClick);
    });
  }

  componentDidUpdate() {
    this.setupPhotoSwipeGallery();
    if ((!this._hasCheckedHashNavigation) && (this.state.loading==false)) {
      this._hasCheckedHashNavigation = true;
      this.scrollToChapterAnchorFromHashValue();
    }
  }

  scrollToChapterAnchorFromHashValue() {
    let hashValue = document.location.hash;

    if ((hashValue==null) || (!hashValue.startsWith('#/'))) {
      return;
    }

    let chapterIndex = hashValue.substring(2);
    let chapterAnchor = document.querySelector(`a[name='${chapterIndex}']`)

    if (chapterAnchor!=null) {
      chapterAnchor.scrollIntoView();
    }
  }

  render () {
    let chapters = this.state.chapters.map( (chapter, index) => <CosChapter key={chapter.id} index={index} {...chapter}/>)

    let previewButton = this.state.draft_enabled ? (
      <span>
        { this.state.preview_mode ? 'Draft Version' : 'Published Version' }
        &nbsp;
        <a href="#"
          className={ this.state.loading ? 'btn btn-outline-primary btn-disabled' : 'btn btn-outline-primary' }
          data-toggle='button'
          aria-pressed='false'
          autoComplete='off'
          disabled={this.state.loading}
          onClick={(event) => { this.togglePreviewMode(event) }}>
          <i className="fa fa-exchange"></i>
        </a>
      </span>
    ) : null

    return (
      <div className='cos'>
        <div className="preview-mode do-not-print">
          { previewButton }
        </div>
        { this.state.loading ? null : <CosTableOfContents chapters={this.state.chapters} /> }
        { this.state.loading ? <p>Loading...</p> : chapters }
        <BackToTheTopButton/>
        <PhotoSwipeGallery/>
      </div>
    );
  }
}

export default Cos
