import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../../../css/parapan.css";
import { connect } from "react-redux";
import NotificationsManager from "../../../extracted_components/NotificationsManager";

class BookingPhaseList extends NotificationsManager {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.notificationDOMRef = React.createRef();
    this.state = {
      events: [],
      selected_event_id: null,
      selected_sport: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    axios
      .get("/events.json")
      .then(response => {
        if (this._isMounted) {
          console.log(response, "responseeeeee");
          this.setState({
            events: response.data,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showAlert = event => {
    this.addNotification(
      this.notificationDOMRef,
      "Error",
      "Please select a " + settings["customer_name"] + " first",
      "warning"
    );
    event.preventDefault();
    event.stopPropagation();
  };

  customerHasBeenSelected = (e, event) => {
    console.log(event.id, "event");
    this.setState({ selected_event_id: event.id });
    // if (this.props.customerId !== null){
    //   this.props.storeBookingPhase(bphase)
    // } else {
    //   this.showAlert(event)
    // }
  };

  filterSports = event_id => {
    return this.state.events.filter(event => event.id === event_id);
  };

  handleSportClick = sport => {
    this.setState({ selected_sport: sport });
  };

  render() {
    document.getElementsByClassName("btn-logout")[0].style.display = "none";
    let bookings = null;
    let card = null;
    let sports = null;
    let footer = null;
    let sportSectionHeader = () => {
      if (this.state.selected_event_id && this.state.selected_sport) {
        footer = (
          <div className="footer">
            <div className="container d-flex justify-content-between mt-3">
              <div className="footer_text">Selected Sport: {this.state.selected_sport?.name} </div>
              <div>
                <Link
                  to={{
                    pathname: "/orders/new/2",
                    state: { sport: this.state.selected_sport },
                  }}
                  className="btn btn-primary float-right"
                >
                  Next <i className="fa fa-chevron-right"></i>
                </Link>
              </div>
            </div>
          </div>
        );
        return (
          <div className="mt-5 label selected">
            You have selected {this.state.selected_sport?.name}
          </div>
        );
      }
      if (this.state.selected_event_id) {
        footer = null;
        return <div className="mt-5 label">Please choose a sport:</div>;
      }
    };

    if (this.state.events && this.state.events.length > 0) {
      bookings = this.state.events.map(event => {
        return (
          <div
            key={event.id}
            onClick={e => {
              this.customerHasBeenSelected(e, event);
            }}
            className={
              this.state.selected_event_id == event.id
                ? "ml-1 mr-1 card col-3 selected"
                : "ml-1 mr-1 card col-3"
            }
          >
            <div className="card-body">
              {/* <div className="cos_chapter"><div className='arrow-right'/>{ event.name }</div> */}
              <h5 className="title">{event.name}</h5>
            </div>
          </div>
        );
      });
    } else {
      bookings = <p>Loading...</p>;
    }
    <div>
      <table></table>
    </div>;
    if (this.state.selected_event_id) {
      sports = (
        <div className="services-box show-scrollbar">
          <table className="table">
            <tbody>
              {this.state.events
                .filter(event => event.id === this.state.selected_event_id)[0]
                .sports.map(sport => {
                  return (
                    <tr
                      key={sport.id}
                      className={sport.id === this.state.selected_sport?.id ? "selected" : ""}
                    >
                      <td onClick={e => this.handleSportClick(sport)}>{sport.name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      );
      // console.log(this.state.events.filter(event => event.id === this.state.selected_event_id)[0], 'filter')
      // sports = this.state.events.filter(event => event.id === this.state.selected_event_id)[0].sports.map((sport) => {
      //   return(
      //     <tr key={sport.id}>{sport.name}</tr>
      //   )
      // })
    }

    return (
      <div className="parapan">
        <NotificationsManager notificationDOMRef={this.notificationDOMRef} />
        <div className="label">Please choose an event:</div>
        <div className="select-mrl-input">
          <div className="row booking-phase-list">{bookings}</div>
        </div>
        {sportSectionHeader()}
        {sports}
        {footer}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bp_id: state.booking_phase ? state.booking_phase.id : null,
    bf_id: state.order.booking_form_id,
    customerId: state.order.customer_id,
    ts: state.ts,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    storeBookingPhase: booking_phase =>
      dispatch({ type: "STORE_BOOKING_PHASE", value: booking_phase }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingPhaseList);
