import React, { useEffect } from "react";
import { Col, FormControl, ListGroup, Row, Spinner } from "react-bootstrap";
import { useCustomers, useEvents } from "../../../apis/ApiCall";
import NewOrderFooter from "../NewOrderFooter";
import { useNewOrderContext } from "../NewOrderContext";
import { Link } from "react-router-dom";

function CustomerSelector({ customers }) {
  const { newOrder, dispatch, computed } = useNewOrderContext();
  const { customer_id, contact_id } = computed.mrl || {};

  useEffect(() => {
    if (customers.length == 1) {
      setMrl();
    }
  }, [customer_id, contact_id]);

  function setMrl() {
    const customer = customers[0];
    dispatch({
      type: "setMrl",
      mrl: { customer_id: customer.id, contact_id: customer.contact_id },
    });
  }

  if (customers.length == 1) {
    return <></>;
  } else {
    return (
      <FormControl
        as={"select"}
        custom
        value={computed.mrl ?`${customer_id},${contact_id}` : ""}
        className={"mb-3"}
        onChange={e => {
          const val = e.target.value;
          if (!val) {
            dispatch({ type: "setMrl", mrl: null });
          }
          const [customer_id, contact_id] = val.split(",").map(Number);
          dispatch({ type: "setMrl", mrl: { customer_id, contact_id } });
          dispatch({
            type: "setBookingServices",
            bookingServices: [],
          });
          dispatch({
            type: "setEvent",
            event: null,
          });
        }}
      >
        <option value="" disabled>
          Choose Client
        </option>
        {customers.map(customer => (
          <option key={customer.id} value={`${customer.id},${customer.contact_id}`}>
            {customer.code}
          </option>
        ))}
      </FormControl>
    );
  }
}

function ChooseEventSport() {
  const events = useEvents()?.data?.events;
  const customers = useCustomers()?.data;
  const { newOrder, dispatch, computed } = useNewOrderContext();

  let eventsByCustomer = () => {
    if(!computed.mrl) return []
    const getCustomerEnrollment = computed.eventEnrollments.filter(eventCus => eventCus.customer_id === computed.mrl.customer_id).map(e => e.event_id)
    const filterCustomerEvents = events.filter(event => getCustomerEnrollment.includes(event.id))
    const uniqEventByCustomer = [...new Map(filterCustomerEvents.map(event =>
      [event["id"], event])).values()];
    return uniqEventByCustomer
  }

  if (!(events && customers)) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <>
        <h2 className={"mb-4"}>Create new order - Step 1</h2>
        <Row>
          <Col sm={5}>
            <CustomerSelector customers={customers} />
          </Col>
        </Row>

        <Row>
          <Col sm={5}>
            <ListGroup
              style={{
                maxHeight: "calc(100vh - 380px)",
                overflowY: "auto",
              }}
            >
              {computed.mrl && eventsByCustomer().map(evt => (
                <ListGroup.Item
                  action
                  key={evt.id}
                  active={computed.event_id === evt.id}
                  onClick={() => {
                    dispatch({
                      type: "setEvent",
                      event: evt,
                    });
                    dispatch({
                      type: "setBookingServices",
                      bookingServices: [],
                    });
                  }}
                >
                  {evt.name}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col sm={7}>
            <ListGroup
              style={{
                maxHeight: "calc(100vh - 380px)",
                overflowY: "auto",
              }}
            >
              {newOrder?.event?.id &&
                newOrder?.event?.id &&
                eventsByCustomer()
                  .find(evt => evt.id === newOrder.event.id)
                  .sports.sort(function (a, b) {
                    return (
                      (a.sport_rank === undefined || a.sport_rank === null) -
                        (b.sport_rank === undefined || b.sport_rank === null) ||
                      a.sport_rank - b.sport_rank ||
                      a.discipline_rank - b.discipline_rank
                    );
                  })
                  .map(spt => (
                    <ListGroup.Item
                      key={spt.id}
                      action
                      active={computed.sport_id === spt.id}
                      onClick={() => {
                        dispatch({
                          type: "setSport",
                          sport: spt,
                        });
                      }}
                    >
                      {spt.name} {spt.discipline} {spt.gender}
                    </ListGroup.Item>
                  ))}
            </ListGroup>
          </Col>
        </Row>
        {computed.event && computed.sport && computed.mrl && (
          <NewOrderFooter
            rightSlot={
              <Link to={"/orders/new/2"} className="btn btn-primary">
                Next <i className="fa fa-chevron-right"></i>
              </Link>
            }
          >
            Event: {newOrder.event.name}
            <span style={{ width: "2ch" }} />
            Sport: {newOrder.sport.name} {newOrder.sport.discipline} {newOrder.sport.gender}
          </NewOrderFooter>
        )}
      </>
    );
  }
}

export default ChooseEventSport;
