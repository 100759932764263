import React from "react"
import Orderlines from '../orderlines/Orderlines'
//import '../css/Orders.css';
class Esads extends React.Component {
  render () {
    document.getElementsByClassName("btn-logout")[0].style.display = ""

    return (
      <div>
        <Orderlines isEsad={true}/>
      </div>
    );
  }
}

export default Esads