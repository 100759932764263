import React, { useState } from "react";
import "../../../../css/target.css";

export default function TargetList(props) {
  const [selectedCategoryType, setSelectedCategoryType] = useState(null);
  const [targetsByCategoryType, setTargetsByCategoryType] = useState(null);
  const [services, setServices] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);

  const handleTypeChecked = type => {
    setSelectedCategoryType(type);
    //filter target by the selected type
    const selectedTargets = props.sport.targets.filter(
      target => target.category.category_type == type
    );
    setTargetsByCategoryType(selectedTargets);
    setSelectedTargets([]);
    props.setService([]);
  };

  const fitlerServiceSelectedTargetType = selectedTargets => {
    let allServices = [];
    selectedTargets.map(target => target.services.map(service => allServices.push(service)));

    const uniqService = allServices.reduce((accumulator, current) => {
      if (!accumulator.find(item => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    let sortService = uniqService.sort((s1, s2) => {
      return s1.code - s2.code;
    });
    props.setService(sortService);
  };

  const handleTargetClick = target => {
    if (selectedTargets.some(t => t.id === target.id)) {
      const newTargetList = selectedTargets.filter(t => t.id !== target.id);
      setSelectedTargets(newTargetList);
      //filter services from selectedTargets
      fitlerServiceSelectedTargetType(newTargetList);
    } else {
      setSelectedTargets([...selectedTargets, target]);
      //filter services from selectedTargets
      fitlerServiceSelectedTargetType([...selectedTargets, target]);
    }
  };

  let targetList = (
    <div className="target-list mt-3">
      <div>Select one or more:</div>
      <table className="table table-hover">
        <tbody>
          {targetsByCategoryType &&
            targetsByCategoryType.map(target => (
              <tr
                className={selectedTargets.some(t => t.id === target.id) ? "selected" : ""}
                key={target.id}
              >
                <td onClick={() => handleTargetClick(target)} className="p-2">
                  {target.start_time}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );

  if (!props.sport) {
    return <p>Loading...</p>;
  } else {
    return (
      <div className="col-3 show-scrollbar left-section">
        <div>
          <div className="sport p-2">{props.sport.name}</div>
          {props.sport.category_types.map((type, i) => (
            <div key={i} className="form-check m-1">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id={"flexRadioDefault" + i}
                onClick={e => handleTypeChecked(type)}
              ></input>
              <label className="form-check-label">{type}</label>
            </div>
          ))}
          {selectedCategoryType && targetList}
        </div>
      </div>
    );
  }
}
