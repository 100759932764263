import React, { useState } from 'react'
import { Collapse } from 'react-bootstrap'

export const CustomCollapse = ({ title, children }) => {
  let [collapsed, setCollapsed] = useState(true)

  return (
    <div className="collapse-container">
      <div
        className={collapsed ? 'collapse-header collapsed' : 'collapse-header'}
        onClick={() => {
          setCollapsed(!collapsed)
        }}
      >
        {title && <h1 className="title">{title}</h1>}
        <i
          className={
            collapsed
              ? 'fa fa-angle-down collapse-button'
              : 'fa fa-angle-up collapse-button'
          }
        />
      </div>
      <Collapse in={!collapsed}>{children}</Collapse>
    </div>
  )
}
