import NotificationsManager from './NotificationsManager'
import ReactNotification from "react-notifications-component";
import DateTime from 'react-datetime';

export const inputIsValid = (service, value, input, classobj) => {
  var regDigits = /^\d+$/ ;
  switch (input) {
    case 'quantity':
      if (value == ''){
        service.isValid = true;
        return false;
      }

      if (value > 200){
        classobj.addNotification(classobj.notificationDOMRef, 'Error', 'The maximum quantity is 200', 'warning')
        service.isValid = false;
        return false;
      }

      if (regDigits.test(value) === false || value == 0){
        if (service.isValid) { classobj.addNotification(classobj.notificationDOMRef, 'Error', 'Please enter a quantity', 'warning') }

        service.isValid = false;
        return false;
      } else {
        service.isValid = true;
        return true;
      }
      break;
    case 'start_time': case 'end_time':
      if (value == ''){
        service.isValid = true;
        return false;
      }

      if (typeof(value.match) == 'function' && value.match(/^\d{1,2}\:?\d{0,2}$/) == null) {
        if (service.isValid) { classobj.addNotification(classobj.notificationDOMRef, 'Error', 'Please input time in 0:00 format', 'warning') }
        service.isValid = false;
        return false;
      } else {
        if (typeof(value.match) == 'function' && value.match(/^\d{1,2}\:\d{2}$/) != null && typeof(value.format) != 'function') {
          service.isValid = false;
          return false;
        } else {
          service.isValid = true;
          return true;
        }
      }
      break;
    default:
      return;
  }
}

