import React from "react";
import { Link } from "react-router-dom";
import "../../../../css/Service.css";
import "../../../../css/react-datetime.css";

export default function ServicesList(props) {
  let table = (
    <table className="table table-hover">
      <tbody>
        {/* <thead>
          <tr>
          <th scope="col">Service</th>
          <th scope="col">Description</th>
       </tr>
        </thead> */}
        <tr className="thead">
          <td>Service</td>
          <td></td>
        </tr>
        {props.services.map(service => {
          return (
            <tr key={service.id}>
              <td>{service.code}</td>
              <td>{service.description}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  return (
    <div className="col-9 services-box show-scrollbar">
      <div className="services-list">
        <div>{table}</div>
        <footer className="footer">
          <div className="container">
            {/* { order_prices } */}
            <div className="next_button">
              <Link
                onClick={event => {}}
                to={"/orders/new/3"}
                className="btn btn-primary float-right"
              >
                Next <i className="fa fa-chevron-right"></i>
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
