import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { useNewOrderContext } from "../NewOrderContext";
import { useCustomers } from "../../../apis/ApiCall";
import "../../../css/Service.css";
import axios from "axios";

function FormVerificationStep() {
  const { newOrder, dispatch, computed } = useNewOrderContext();
  const [submitting, setSubmitting] = useState(false);
  const [orderlines, setOrderlines] = useState([]);
  const [submissionErrorMessage, setSubmissionErrorMessage] = useState(false);
  const bookingServices = computed.bookingServices;
  const customers = useCustomers()?.data;

  useEffect(() => {
    setOrderlines(assignTargetToServices());
  }, []);

  const assignTargetToServices = () => {
    const services = bookingServices.filter(
      service => service.requested_quantity || service.pre_post_slot
    );
    const targets = computed.targets;
    let orderlines = [];
    for (let i = 0; i < targets.length; i++) {
      for (let s = 0; s < services.length; s++) {
        orderlines.push({
          ...services[s],
          target_id: targets[i].id,
          target_name: targets[i].name,
          warm_up_match: targets[i].warm_up_match,
        });
      }
    }
    orderlines.sort((a,b)=> {
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    })
    return orderlines;
  };

  const SubmitButton = withRouter(({ history }) => (
    <button
      onClick={() => {
        createOrder(history);
      }}
      className="btn btn-primary btn-lg"
    >
      {submitting ? "Submitting..." : "Submit"}
      &nbsp;<i className="fa fa-chevron-right"></i>
    </button>
  ));

  const displayTooltipsAccordingToTos = submitButton => {
    if (agreeToTerms) {
      return submitButton;
    } else {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              Please check the box confirming you agree with the Terms &amp; Conditions
            </Tooltip>
          }
        >
          <span>{submitButton}</span>
        </OverlayTrigger>
      );
    }
  };

  const orderlineTotalPrice = orderline => {
    return orderline.warm_up_match ? 0 : parseInt(orderline.requested_quantity) * orderline.price;
  };

  const calculateTotal = () => {
    let sum = null;
    orderlines.forEach(orderline => {
      if (!orderline.warm_up_match) {
        sum += orderline.price * parseInt(orderline.requested_quantity);
      }
    });
    return sum ? `${sum}` : 0;
  };

  const moneyFormatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let displayOrderlines = orderlines.map((orderline, i) => {
    return (
      <tr key={i}>
        <td className="target align-top">
          <div>
            {orderline.target_name}{" "}
            {orderline.warm_up_match && <span className="badge badge-info">Test Match</span>}{" "}
          </div>
        </td>
        <td className="service align-top">
          <div>
            {orderline.code} - {orderline.description}
          </div>
          <div className="public_note">{orderline.tech_note}</div>
        </td>
        <td className="unit_price align-top">
          {computed.currency} {moneyFormatter.format(orderline.price)}
        </td>
        <td className="quantity align-top">{orderline.requested_quantity}</td>
        <td className="price align-top">
          {computed.currency} {moneyFormatter.format(orderlineTotalPrice(orderline))}
        </td>
      </tr>
    );
  });

  const createOrder = history => {
    const { contact_id, customer_id } = computed.mrl;

    setSubmitting(true);
    const orderlines = cleanUpEmptyServices();
    // TODO notification
    // TODO change to use reactQuery?
    axios
      .post("/orders.json", {
        order_lines: orderlines,
        order: { contact_id, customer_id },
      })
      .then(response => {
        console.log({ response });
        if (response.data && parseInt(response.data.ordernumber) > 0) {
          history.push("/orders/" + response.data.ordernumber);
          cleanUpStates();
        } else {
          setSubmissionErrorMessage(true);
        }
      })
      .catch(error => {
        console.log({ error });
        setSubmissionErrorMessage(true);
      });
  };

  const cleanUpEmptyServices = () => {
    const orderlinesPayload = orderlines.map(orderline => {
      delete orderline.description;
      delete orderline.code;
      delete orderline.target_name;
      delete orderline.warm_up_match;
      return orderline;
    });
    return orderlinesPayload;
  };

  const cleanUpStates = () => {
    dispatch({
      type: "setCategoryType",
      target_category_id: "",
    });
    dispatch({
      type: "setTargets",
      targets: [],
    });
    dispatch({
      type: "setBookingServices",
      bookingServices: [],
    });
    dispatch({
      type: "setEvent",
      event: {},
    });
    dispatch({
      type: "setSport",
      sport: {},
    });
    customers.length > 1 && dispatch({ type: "setMrl", mrl: null });
  };

  if (submissionErrorMessage) {
    return (
      <div>
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading">Something went wrong!</h4>
          <p>The order creation failed.</p>
          <div>
            <Link to={"/orders"} className="btn btn-lg btn-outline-secondary">
              <i className="fa fa-chevron-left"></i>&nbsp; Back to orders
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="form-verification-step-container">
      <div className="order-step-three">
        <h1>Create a new order - Step 3</h1>
        <h4>Confirm the following order</h4>
      </div>
      <div className="orderlines-list-container">
        <div className="orderlines-list show-scrollbar">
          <div className="orderlines-list-table">
            <table className="table">
              <tbody>{displayOrderlines}</tbody>
            </table>
          </div>
        </div>
        <footer className="footer step-two">
          <div className="container">
            <ul className="buttons">
              <li>
                <Link to={"/orders/new/2"} className="btn btn-outline-primary">
                  <i className="fa fa-chevron-left"></i>&nbsp; Modify
                </Link>
              </li>
              <li>
                <Link
                  to={"/orders/new/"}
                  onClick={() => cleanUpStates()}
                  className="btn btn-outline-secondary"
                >
                  <i className="fa fa-ban"></i>&nbsp; Cancel
                </Link>
              </li>
              <li id="submit_button">
                <div id="order_prices">
                  Total Price of the Order:&nbsp;
                  {computed.currency} {moneyFormatter.format(calculateTotal())}
                </div>
                <div id="terms_and_conditions">
                  <label htmlFor="agree_to_terms_and_conditions">
                    By submitting this order, you certify being duly appointed to place this order
                    and acknowledge the acceptance of Mediapro Terms and Conditions included in the
                    rate card.
                  </label>
                </div>
                <div id="submit_button_container">
                  <SubmitButton />
                </div>
              </li>
            </ul>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default FormVerificationStep;
