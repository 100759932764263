import React from "react";
import { Nav, Navbar } from "react-bootstrap";

function NewOrderFooter({ children, rightSlot }) {
  return (
    <Navbar bg="light" variant="light" fixed={"bottom"} style={{ padding: "1rem 0" }}>
      <div className="container">
        <Nav className="mr-auto">{children}</Nav>
        {rightSlot}
      </div>
    </Navbar>
  );
}

export default NewOrderFooter;
