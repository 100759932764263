import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import '../css/Home.scss'

const Home = () => {
  const [contents, setContents] = useState([])
  const isRequested = useRef()

  useEffect(() => {
    if (isRequested.current) return

    const url = '/?format=json'

    axios.get(url)
      .then(response => {
        setContents(response.data)
        isRequested.current = true
      }).catch(error => {
        isRequested.current = true
        console.log(error);
      });
  }, [contents])

  return (
    <div className='jumbotron'>
      <div className='container'>
        {
          contents.map((content, index) => {
            return (content.setting || content.should_have_early_access) &&
              <p key={`${content.name}_${index}`}>
                <a
                  href={content.path}
                  className={content.class}>
                  {content.name}
                </a>
              </p>
          })
        }
      </div>
    </div >
  )
}

export default Home
