import React, { createContext, useContext, useReducer } from "react";
import { useEvents } from "../../apis/ApiCall";

const NewOrderContext = createContext(null);

export function NewOrderProvider({ children }) {
  const allServices = useEvents()?.data?.services;
  const eventEnrollments = useEvents()?.data?.event_enrollments
  const [newOrder, dispatch] = useReducer(newOrderReducer, {});

  const event_id = newOrder?.event?.id;
  const sport_id = newOrder?.sport?.id;
  const sport = newOrder?.sport;
  const event = newOrder?.event;
  const target_category_id = newOrder?.target_category_id;
  const targets = newOrder?.targets;
  const mrl = newOrder?.mrl;
  const currency = useEvents()?.data?.currency;
  const targetsBySportByCategoryType = (function () {
    if (!sport_id) return [];
    if (!target_category_id) return [];
    return newOrder.sport.targets.filter(t => target_category_id === t.category?.id);
  })();

  const servicesByCategoryType = (function () {
    if (!target_category_id) return [];
    if (!allServices) return [];
    return allServices.find(as => as.category_type.id === target_category_id)?.services ?? [];
  })();

  const computed = {
    event_id,
    sport_id,
    event,
    sport,
    target_category_id,
    targets,
    stepOneComplete: event_id && sport_id,
    targetsBySportByCategoryType,
    servicesByCategoryType,
    eventEnrollments,
    currency,
    mrl,
    bookingServices: newOrder?.bookingServices ?? [],
  };

  return (
    <NewOrderContext.Provider
      value={{
        newOrder, // state
        dispatch, // setters
        computed, // getters
      }}
    >
      {children}
    </NewOrderContext.Provider>
  );
}

export function useNewOrderContext() {
  return useContext(NewOrderContext);
}

function newOrderReducer(newOrder, action) {
  switch (action.type) {
    case "setEvent": {
      const { event } = action;
      return {
        ...newOrder,
        event,
      };
    }
    case "setSport": {
      const { sport } = action;
      return {
        ...newOrder,
        target_category_id: null,
        sport,
        bookingServices: [],
      };
    }
    case "setCategoryType": {
      const { target_category_id } = action;
      return {
        ...newOrder,
        target_category_id,
        bookingServices: [],
      };
    }
    case "toggleTarget": {
      const { target } = action;
      if (!newOrder?.targets) {
        return {
          ...newOrder,
          targets: [target],
        };
      }
      const { targets } = newOrder;
      const alreadySelected = new Set(targets.map(t => t.id)).has(target.id);
      const newTargets = alreadySelected
        ? targets.filter(t => t.id !== target.id)
        : [...targets, target];
      return {
        ...newOrder,
        targets: newTargets,
      };
    }
    case "setTargets": {
      const { targets } = action;
      return {
        ...newOrder,
        targets,
      };
    }
    case "setMrl": {
      const { mrl } = action;
      return {
        ...newOrder,
        mrl,
      };
    }
    case "setBookingServices": {
      const { bookingServices } = action;
      return {
        ...newOrder,
        bookingServices,
      };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}
