const initialState = {
  booking_phase: null,
  targets: [],
  services: [],
  orderlines: [{
    orderline: {
      id: null,
      target: null,
      service: null,
      quantity: null,
      total: null
    }
  }],
  order: {
    customer_id: null,
    contact_id: null,
    booking_form_id: null,
    target_ids: [],
    orderlines: [{
      service_id: null,
      requested_quantity: null,
      requested_start_time: null,
      requested_end_time: null,
      should_prompt_slot_number: null,
      slot: null
    }]
  },
  loading: false,
  isEdit: false,
  clicked: false,
  totalPrice: null,
  totalPricePermatch: null,
  totalPriceByCurrency: null,
  ts: (new Date).getTime()
}

const reducerUser = (state = initialState, action) => {
  if (action.type === 'STORE_BOOKING_PHASE') {
    return {
      ...state,
      services: [],
      booking_phase: action.value,
      order: {
        ...state.order,
        target_ids: [],
        orderlines: [],
        booking_form_id: action.value.booking_form.id
      }
    }
  }

  if(action.type === 'STORE_CONTACT_ID'){
    return{
      ...state,
      order: {
        ...state.order,
        contact_id: action.contact_id
      }
    }
  }

  if (action.type === 'STORE_CUSTOMER_ID') {
    return {
      ...state,
      order:{
        ...state.order,
        customer_id: action.customer_id,
        contact_id: action.contact_id
      },
      ts: (new Date).getTime()
    };
  }

  if (action.type === 'REMOVE_CUSTOMER_ID') {
    return{
      ...state,
      order:{
        ...state.order,
        customer_id: null
      }
    }
  }

  if (action.type === 'FETCH_TARGETS'){
    return {
      ...state,
      targets: action.payload
    }
  }

  if (action.type === "FETCH_SERVICES"){
    return{
      ...state,
      services: action.payload,
      loading: true
    }
  }

  if (action.type === "STORE_SERVICE"){
    return{
      ...state,
      isEdit: false,
      totalPrice: action.total_price,
      totalPricePermatch: action.total_price_per_match,
      totalPriceByCurrency: action.total_price_by_currency,
      orderlines: [
        ...state.orderlines.filter( o => (o.orderline.id !== null && o.orderline.quantity !==  "") ),
        {
          orderline: {
            id: Math.random(),
            target: action.target_name,
            teams: action.teams,
            rank: action.rank,
            quantity: action.quantity,
            service: action.service,
            serviceCode: action.serviceCode,
            serviceDescription: action.serviceDescription,
            total: action.total,
            unitPrice: action.unitPrice,
            startTime: action.start_time,
            endTime: action.end_time,
            should_prompt_slot_number: action.should_prompt_slot_number,
            slot: action.slot,
            warm_up_match: action.warm_up_match,
            currency: action.currency
          }
        }
      ],
      order:{
        ...state.order,
        orderlines: [...state.order.orderlines.filter( o => (o.service_id !== null && o.requested_quantity !==  "") ),{
          target_id: action.target_id,
          service_id: action.service,
          requested_quantity: action.quantity,
          requested_start_time: action.start_time,
          requested_end_time: action.end_time,
          pre_post_slot: action.slot
        }]
      }
    }
  }

  if (action.type === "STORE_SERVICES"){
    return{
      ...state,
      services: action.services
    }
  }

  if (action.type === "STORE_TARGET_IDS"){
    return{
      ...state,
      order:{
        ...state.order,
        target_ids: [...state.order.target_ids.filter(target_id => (target_id.id !== null) && (target_id.id !== action.id)),{
          id: action.id,
          name: action.name,
          name_with_kick_off: action.name_with_kick_off,
          teams: action.teams,
          rank: action.rank,
          start_time: action.start_time,
          end_time: action.end_time,
          warm_up_match: action.warm_up_match
        }]
      },
      clicked: true
    }
  }

  if (action.type === "REMOVE_TARGET_IDS"){
    return{
      ...state,
      order: {
        ...state.order,
        target_ids: [...state.order.target_ids.filter(target => target.id !== action.id)]
      },
      clicked: false
    }

  }

  if (action.type === "EDIT_ORDER_LINK"){
    return{
      ...state,
      isEdit: true,
      orderlines: [],
      order: {
        ...state.order,
        orderlines: []
      }
    }
  }

  if (action.type === "CANCEL_ORDER_LINK"){
    return{
      ...state,
      order: {
        ...state.order,
        target_ids: [],
        orderlines: []
      },
      orderlines: []
    }
  }

  if (action.type === 'RESET_STATE'){
    return{
      ...state,
      booking_phase: null,
      targets: [],
      services: [],
      orderlines: [],
      order:{
        ...state.order,
        target_ids: [],
        orderlines: [],
        booking_form_id: null
      },
      totalPrice: null,
      totalPricePermatch: null,
      totalPriceByCurrency: null
    }
  }

  return state;
}

export default reducerUser;