import React from "react"
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";


class NotificationsManager extends React.Component{
  constructor(props){
    super(props)
  }

  addNotification(domRef, title, message, type, time) {
    // 3 seconds by default
    time = typeof time !== "undefined" ? time : 3000;

    domRef.current.addNotification({
      title: title,
      message: message,
      type: type, // (default, success, info, danger, warning)
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: time },
      dismissable: { click: true }
    })
  }

  render(){
    return(
      <ReactNotification ref={this.props.notificationDOMRef} />
    )
  }
}

export default NotificationsManager