import React from 'react'

class CosTableOfContentsEntry extends React.Component {
  render () {
    const number = this.props.chapter.number;
    const title = this.props.chapter.title;
    const chapters = this.props.chapter.chapters ? Object.keys(this.props.chapter.chapters).map((chapterNumber, index) =>
      <CosTableOfContentsEntry key={index} index={index} chapter={this.props.chapter.chapters[chapterNumber]} level={parseInt(this.props.level) + 1} />
    ) : null;

    return <li>
            <a href={`cos#${number}`} aria-haspopup="true" title={number + ' ' + title}>
              <span className="chapter_number">{number}</span>
              <span className="chapter_title">{title}</span>
              { (chapters && (chapters.length > 0)) && <span className="chevron"><i className="fa fa-angle-right" aria-hidden="true"></i></span>}
            </a>
            {
              (chapters && (chapters.length > 0)) &&
              <ul className={"menu level-" + (parseInt(this.props.level) + 1) + " position-" + this.props.index}>
                {chapters}
              </ul>
            }
           </li>
  }
}

export default CosTableOfContentsEntry
