/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Support component names relative to this directory:
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import dva from "dva-no-router";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import reducerUser from "../store/reducer_user";
import thunk from "redux-thunk";
import { BrowserRouter, HashRouter, Route } from "react-router-dom";
import { Switch } from "react-router";
import Home from "../components/Home";
import Orders from "../components/Orders";
import OrderSummary from "../components/OrderSummary";
import Esads from "../components/esads/Esads";
import "bootstrap/dist/css/bootstrap.css";
import "../css/Orderline.css";
import "../css/Orders.css";
import CosFC from "../components/CosFC";
import ChooseEventSport from "../components/orderlines/ChooseEventSport";
import { NewOrderProvider } from "../components/orderlines/NewOrderContext";
import ChooseTargetService from "../components/orderlines/ChooseTargetService";
import FormVerificationStep from "../components/orderlines/FormVerificationStep";
import Notification from "../components/NotificationContainer";

function initReact() {
  if (document.getElementById("root") == null) {
    return;
  }
  const store = createStore(reducerUser, applyMiddleware(thunk));

  var componentRequireContext = require.context("components", true);
  var ReactRailsUJS = require("react_ujs");
  ReactRailsUJS.useContext(componentRequireContext);

  const handleError = e => {
    console.error("!!! ERROR !!!", e.message);
  };

  const app = dva({
    onError: handleError,
  });

  app.router(() => {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/orders/new/3" component={FormVerificationStep} />
          <Route exact path="/orders/new/2" component={ChooseTargetService} />
          <Route exact path="/orders/new" component={ChooseEventSport} />
          <Route exact path="/orders" component={Orders} />
          <Route path="/orders/:number" component={OrderSummary} />
          <Route path="/cos" component={CosFC} />
          <Route
            path="/guidelines"
            render={props => (
              <CosFC
                {...props}
                filterChaptersBy={chapter => chapter?.cos_services?.some(cos_service => !!cos_service.guidelines)}
              />
            )}
          />
          <Route
            path="/technical_leaflets"
            render={props => (
              <CosFC
                {...props}
                filterChaptersBy={chapter =>
                  chapter?.cos_services?.some(cos_service => !!cos_service.technical_leaflets)
                }
              />
            )}
          />
          <Route path="/esads" component={Esads} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    );
  });

  const App = app.start();
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <NewOrderProvider>
          <Notification/>
          <App />
        </NewOrderProvider>
      </HashRouter>
    </Provider>,
    document.getElementById("root")
  );
}

initReact();

document.addEventListener("DOMContentLoaded", () => {
  initReact();
});
