import React from 'react'
import { CustomCollapse } from '../extracted_components/CustomCollapse'
export const GuidelineLeaflet = ({ guidelineleaflet, pageType, title }) => {
  return (
    pageType ? (<div dangerouslySetInnerHTML={{ __html: guidelineleaflet }}></div>) :
      (<CustomCollapse title={title}>
        <div>
          <div className='collapse-context'
            dangerouslySetInnerHTML={{ __html: guidelineleaflet }}
          ></div>
        </div>
      </CustomCollapse>)
  )
}
