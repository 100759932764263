import React from "react";
import MrlSelect from "../orderlineform/ordersteponeform/Mrlselect";
import "../../../css/Orderstepone.css";
import BookingPhaseList from "./ordersteponeform/Bookingphaselist";

class Orderstepone extends React.Component {
  render() {
    return (
      <div>
        <div className="order-step-one">
          <h1>Create a new order - Step 1</h1>
        </div>
        <MrlSelect />
        <BookingPhaseList />
      </div>
    );
  }
}

export default Orderstepone;
