import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Order from './Order';
import { connect } from 'react-redux';

class OrderSummary extends React.Component {
  _isMounted = false;

  constructor(props){
    super(props);
    this.state = {
      order: {},
      loading: true
    }
  }

  componentDidMount () {
    this._isMounted = true;
    this.props.resetState();
    const number = this.props.match.params.number;

    axios.get('/orders/' + number + '.json')
      .then(response =>{
        const order = response.data;
        if (this._isMounted) {
          this.setState({order: order, loading: false});
        }
      }).catch(error => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    const { order, loading } = this.state;

    return (
      <div className='order-summary-container'>
        <h1>Your order has been received</h1>

        { loading ? <p>Loading...</p> : <Order order={order}/>}

        <ul className="buttons">
          <li>
            <Link to={'/orders'} className="btn btn-lg btn-outline-secondary">
              <i className="fa fa-chevron-left"></i>&nbsp;
              Back to orders
            </Link>
          </li>
          <li>
            <button onClick={window.print} className="btn btn-lg btn-outline-info">
              <i className="fa fa-print"></i>&nbsp;
              Print
            </button>
          </li>
          <li className='new-order'>
            <Link to={'/orders/new'} className="btn btn-primary btn-lg">
              <i className="fa fa-plus"></i>&nbsp;
              Create a new order
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return{
  };
}

const mapDispatchToProps = dispatch => {
  return{
    resetState: () => dispatch({ type: "RESET_STATE"})
  };
}

export default connect(mapStateToProps, mapDispatchToProps) (OrderSummary)